import { cleanupContentfulData } from 'utils/contentful-resolve-response';

export const cleanupRestaurantData = arr => {
    const data = arr?.map(item => {
        item = cleanupContentfulData(item);

        const {
            streetAddress,
            city,
            state,
            zipCode,
            phoneNumber,
            website,
            sirvedData,
            images,
            cuisine,
            deliveryLinks,
        } = item;

        const streetAddressSirved = sirvedData?.address.split(',')[0];

        const zipCodeSirved = sirvedData?.address.split(',')[2].split(' ')[2];

        const imagesSirved = (sirvedData?.atmosphere ||
            sirvedData?.foodshots) && [
            ...sirvedData?.atmosphere.map(photo => ({
                file: {
                    url: photo,
                },
            })),
            ...sirvedData?.foodshots.slice(0, 9).map(photo => ({
                file: {
                    url: photo,
                },
            })),
        ];

        const cuisineSived = sirvedData?.cuisine.split(',').join(', ');

        item = {
            streetAddress: streetAddress || streetAddressSirved,
            city: city || sirvedData?.city,
            state: state || sirvedData?.state,
            zipCode: zipCode || parseInt(zipCodeSirved, 10),
            phoneNumber: phoneNumber || sirvedData?.phoneNumber,
            website: website || sirvedData?.website,
            images: images || imagesSirved,
            cuisine: cuisine || cuisineSived,
            deliveryLinks: deliveryLinks || sirvedData?.thirdPartyOrdering,
            ...item,
        };

        return { ...item };
    });

    return data;
};

export const foodIcons = {
    Bagel: {
        src: 'https://images.ctfassets.net/j8tkpy1gjhi5/62MAYLR9qMawLi1Mo2oId4/77936ddd8ba0ea0b9e4eb45aa5817cfb/RestaurantBagel.svg',
    },
    Bowl: {
        src: 'https://images.ctfassets.net/j8tkpy1gjhi5/5AUpRPnxEqXqtfnHTNdtfM/d2b6465d1836e8bcfdc6a10cb37117a0/RestaurantBowl.svg',
    },
    Burger: {
        src: 'https://images.ctfassets.net/j8tkpy1gjhi5/3z2TxeenqT2qYxpjdlmsNp/ea1930675029acb0d5b726837d26009f/RestaurantBurger.svg',
    },
    Burrito: {
        src: 'https://images.ctfassets.net/j8tkpy1gjhi5/6gk8UNXln7EplmFEzpPeSe/c9daa3c7b75316be3385c607aedfe476/RestaurantBurrito.svg',
    },
    'Cheese Board': {
        src: 'https://images.ctfassets.net/j8tkpy1gjhi5/5UZzBbGPOLok1Fr56TIPFn/94ea7cd088162331ea6d2367f1497986/RestaurantCheeseboard.svg',
    },
    Chili: {
        src: 'https://images.ctfassets.net/j8tkpy1gjhi5/5GEfTRtylpAHWbxD4nQzNp/fec49d50d9d6c57872fca2d507d7ac25/RestaurantChili.svg',
    },
    Dessert: {
        src: 'https://images.ctfassets.net/j8tkpy1gjhi5/Yb2LGgI7IfBL9g9u23uoV/edbd273f76ff37d1e4f107bd38b910de/RestaurantDessert.svg',
    },
    Dip: {
        src: 'https://images.ctfassets.net/j8tkpy1gjhi5/3Si9qGzI4EgmmqdVttlhAP/1b268fc6747d736c4c81d8ecb9677d31/RestaurantDip.svg',
    },
    Fries: {
        src: 'https://images.ctfassets.net/j8tkpy1gjhi5/5S9n7pc4lBnHImaHPACtP7/9ccf3694f14309bdeba4daf02ce911a2/RestaurantFries.svg',
    },
    'Mac & Cheese': {
        src: 'https://images.ctfassets.net/j8tkpy1gjhi5/58ewyHf6sKZYCGfRgdmWHe/5924e190342e76d5ac6b5469b7f99ecd/RestaurantMacAndCheese.svg',
    },
    Nachos: {
        src: 'https://images.ctfassets.net/j8tkpy1gjhi5/1ZMxBhnLDZCZL1kPCRABxH/654685a6ab63af253d9689ba0f610a43/RestaurantNacho.svg',
    },
    Omelet: {
        src: 'https://images.ctfassets.net/j8tkpy1gjhi5/6QGEdstaCI5w3rosfTSmaF/da08ba8572fbe5adedcf00c6be7d3db2/RestaurantOmelet.svg',
    },
    Pizza: {
        src: 'https://images.ctfassets.net/j8tkpy1gjhi5/56oqfxyiR6WCi8exfMtEOf/5647406a03ee02a0ed558aceefddf798/RestaurantPizza.svg',
    },
    Quesadilla: {
        src: 'https://images.ctfassets.net/j8tkpy1gjhi5/3JnWdV0xvr5RI37CPZFeEH/bf24043298db5b4b2be17ed613f29201/RestaurantQuesadilla.svg',
    },
    Salad: {
        src: 'https://images.ctfassets.net/j8tkpy1gjhi5/1YkGuhfF2134iSiqCNdWeT/002d07e3e8d29649a6010d89549e2e1b/RestaurantSalad.svg',
    },
    Sandwich: {
        src: 'https://images.ctfassets.net/j8tkpy1gjhi5/4g262d7f78PBoOdWRZH2fB/f0f7db6c69862e37e1f884bb546bcfee/RestaurantSandwich.svg',
    },
    'Side Dish': {
        src: 'https://images.ctfassets.net/j8tkpy1gjhi5/wfVbinGfJkGURViFTBndu/4a68a8107e37787f7c2309520232d65d/RestaurantSideDish.svg',
    },
    Soup: {
        src: 'https://images.ctfassets.net/j8tkpy1gjhi5/5lrLlh8zkd7IxmtFovyeRt/ac9268a957015387f252d7fbedf53748/RestaurantSoup.svg',
    },
    Tacos: {
        src: 'https://images.ctfassets.net/j8tkpy1gjhi5/5jiRUWWfsjFsYYkpUEqw7E/773800ea7e02c3a0ea314f64746e967a/RestaurantTaco.svg',
    },
    Other: {
        src: 'https://images.ctfassets.net/j8tkpy1gjhi5/7g0moJMaNkxdEomTvllpbV/18a47beb83717b0bc6ec3fad905e43c4/RestaurantOther.svg',
    },
};

export const getSortedFilters = (items, type) => {
    const arr = items
        .map(item => item[type])
        .filter(n => n)
        .flat()
        .sort();

    const count = arr.reduce((obj, val) => {
        obj[val] = (obj[val] || 0) + 1;
        return obj;
    }, {});

    const sorted = Object.keys(count).sort((a, b) => {
        return count[b] - count[a];
    });

    return sorted;
};

export const compareArray = (arr1, arr2) => {
    return (
        arr1.length !== arr2.length ||
        (arr1.length === arr2.length && arr1.toString() !== arr2.toString())
    );
};

export const filterByType = (list, filters, type) => {
    return list?.filter(item =>
        filters?.some(filter => item?.[type]?.includes(filter))
    );
};
