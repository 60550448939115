import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

export const Title = ({ title }) => {
    return (
        <Head>
            <title key="title">{title} - Tillamook</title>
        </Head>
    );
};

Title.propTypes = {
    title: PropTypes.string,
};

Title.defaultProps = {
    title: '',
};
