import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useRouter } from 'next/router';
import mapboxClient from '@mapbox/mapbox-sdk';
import mapboxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';

import { useLocation } from 'hooks/useLocation';
import useMediaQuery from 'hooks/useMediaQuery';

import Text from 'components/ui/Text';
import SvgIcon from 'components/ui/SvgIcon';
import ButtonCircle from 'components/ui/ButtonCircle';
import Button from 'components/ui/Button';

import styles from './Search.module.scss';

const MAPBOX_TOKEN =
    'pk.eyJ1IjoiaGF1c2xhIiwiYSI6ImNrbHN0dHRzNjA1d24yd25xZWhka2xmMm8ifQ.C3nu0o6XmETb877Vj6tjfQ';

const Search = ({ className }) => {
    const router = useRouter();
    const ref = useRef();
    const [userLocation] = useLocation();

    const [search, setSearch] = useState('');
    const [result, setResult] = useState([]);
    const [query, updateQuery] = useState({});
    const [showDropdown, setShowDropdown] = useState(false);
    const [prevGeolocationAccuracy, setPrevGeolocationAccuracy] =
        useState(null);

    const isMedium = useMediaQuery('(min-width: 768px)');

    const disableSearch = result?.length === 0 || search?.length === 0;

    const handleSubmit = result => {
        if (router.pathname === '/where-to-eat/map') {
            router.replace(
                {
                    query: result,
                },
                '',
                { shallow: true }
            );
        }
        updateQuery(result);

        setSearch(result.input);
    };

    const getGeocoding = async () => {
        await new Promise(resolve => {
            search && resolve();
        });
        mapboxGeocoding(mapboxClient({ accessToken: MAPBOX_TOKEN }))
            .forwardGeocode({
                query: search,
                countries: ['us'],
                types: [
                    'region',
                    'postcode',
                    'district',
                    'place',
                    'neighborhood',
                    'address',
                    'poi',
                ],
                limit: 5,
            })

            .send()
            .then(res => {
                const {
                    body: { features },
                } = res;
                setResult(features);

                const isLoaded =
                    Object.keys(router.query).length === 0 ||
                    Object.keys(router.query).includes(
                        'input' && 'lat' && 'lon'
                    );

                !isLoaded
                    ? handleSubmit({
                          ...router.query,
                          input: search,
                          lat: features[0]?.center[1],
                          lon: features[0]?.center[0],
                          type: features[0]?.place_type[0],
                      })
                    : updateQuery({
                          ...router.query,
                          input: search,
                          lat: features[0]?.center[1],
                          lon: features[0]?.center[0],
                          type: features[0]?.place_type[0],
                      });
            });
    };

    useEffect(() => {
        getGeocoding();
    }, [search]);

    const initLocation = () => {
        const { city, state, postalCode, coordinates } = userLocation || {};

        const initValues = postalCode
            ? `${city}, ${state} ${postalCode}`
            : `${city}, ${state}`;

        handleSubmit({
            ...query,
            input: initValues,
            lat: coordinates[1],
            lon: coordinates[0],
        });
    };

    useEffect(() => {
        if (!router.isReady) return;

        setSearch(router.query.input);

        const updatedGeolocationAccuracy =
            prevGeolocationAccuracy &&
            prevGeolocationAccuracy !== userLocation?.accuracy;

        if (updatedGeolocationAccuracy) {
            initLocation();
        }

        const queryLoaded = Object.keys(router.query).includes(
            'input' || ('lat' && 'lon')
        );

        if (queryLoaded) return;

        if (!queryLoaded && userLocation) {
            initLocation();
            setPrevGeolocationAccuracy(userLocation?.accuracy);
        }
    }, [userLocation, router.isReady]);

    const handleSearch = evt => {
        setSearch(evt.target.value);
    };

    const renderSubmitContainer = () => {
        const showClose = search !== '' && isMedium;

        return (
            <div className={styles.submitContainer}>
                {showClose && (
                    <span
                        className={styles.closeIcon}
                        onClick={() => setSearch('')}
                    >
                        <SvgIcon iconType="close" />
                    </span>
                )}
                {isMedium ? (
                    <Button
                        onClick={() => {
                            router.asPath === '/where-to-eat'
                                ? router.push({
                                      pathname: '/where-to-eat/map',
                                      query: { input: search },
                                  })
                                : handleSubmit({ ...query, input: search });
                        }}
                        theme="none"
                        className={styles.textButton}
                        disabled={disableSearch}
                    >
                        <Text baseTheme="headingSmall2">Let&#8217;s Eat!</Text>
                    </Button>
                ) : (
                    <ButtonCircle
                        className={styles.circleButton}
                        theme="arrowRightRoundedBlue"
                        disabled={disableSearch}
                        onClick={() => {
                            router.asPath === '/where-to-eat'
                                ? router.push({
                                      pathname: '/where-to-eat/map',
                                      query: { input: search },
                                  })
                                : handleSubmit({ ...query, input: search });
                        }}
                    />
                )}
            </div>
        );
    };

    return (
        <>
            <div className={cx(styles.root, className)}>
                <div className={styles.search}>
                    <Text
                        as="label"
                        baseTheme="bodySmall"
                        themes={{ large: 'bodyMedium' }}
                    >
                        <span
                            className={styles.locationIcon}
                            onClick={() => initLocation()}
                        >
                            <SvgIcon iconType="pin" />
                        </span>
                        <input
                            onFocus={() => setShowDropdown(true)}
                            onBlur={() => setShowDropdown(false)}
                            ref={ref}
                            name="search"
                            placeholder={'Enter a location'}
                            type="text"
                            onChange={handleSearch}
                            value={search}
                            autoComplete="off"
                        />
                    </Text>
                    {renderSubmitContainer()}
                </div>

                <div
                    className={cx(styles.dropdown, {
                        [styles.showDropdown]: showDropdown && search !== '',
                    })}
                >
                    {result.length === 0 && search !== undefined ? (
                        <Button
                            className={cx(
                                styles.noResults,
                                styles.dropdownButton
                            )}
                            theme="none"
                        >
                            <Text baseTheme="labelSmall">
                                No results found.
                            </Text>
                        </Button>
                    ) : (
                        result?.map((item, i) => {
                            return (
                                <button
                                    className={styles.dropdownButton}
                                    key={i}
                                    onMouseDown={() =>
                                        handleSubmit({
                                            ...router.query,
                                            lat: result[i]?.center[1],
                                            lon: result[i]?.center[0],
                                            input: item.place_name,
                                            type: item?.place_type,
                                        })
                                    }
                                >
                                    <SvgIcon
                                        className={styles.dropdownIcon}
                                        iconType="pin"
                                    />
                                    <Text
                                        className={styles.dropdownText}
                                        baseTheme="bodyXSmall"
                                        themes={{ medium: 'bodySmall' }}
                                    >
                                        {item.place_name}
                                    </Text>
                                </button>
                            );
                        })
                    )}
                </div>
            </div>
        </>
    );
};

Search.propTypes = {
    className: PropTypes.string,
    userLocation: PropTypes.object,
};

export default Search;
